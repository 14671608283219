const QueryChangeStageJobMutation = `
  mutation (
    $id: Int!,
    $jobStageId: Int!,
  ) {
    applicantsChangeStage(
      input: {
        id: $id,
        jobStageId: $jobStageId,
      }
    ) {
      applicant {
        id
      }
    }
  }
`

export default QueryChangeStageJobMutation
