'use client'

import { Slot } from '@radix-ui/react-slot'
import { cva } from 'class-variance-authority'
import React, { forwardRef } from 'react'
import { cn } from '~/core/ui/utils'

const ICONS_SOCIAL = {
  facebook: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6714_256058)">
        <path
          d="M11.6383 20H7.70166C7.61812 19.9557 7.55024 19.8867 7.50724 19.8024C7.46423 19.7181 7.44818 19.6226 7.46126 19.5289C7.46126 16.6424 7.46126 13.7658 7.46126 10.8792V10.6387H5.45787C5.09726 10.6387 4.99707 10.5384 4.99707 10.1876V7.1808C4.99707 6.83 5.09725 6.72977 5.44785 6.72977H7.6215V5.8177C7.6215 5.28649 7.6215 4.75528 7.6215 4.23409C7.54251 3.50352 7.6968 2.76669 8.06225 2.1293C8.42606 1.5186 8.93622 1.00822 9.54658 0.644239C10.1569 0.280261 10.8482 0.0741553 11.5582 0.0445459C12.5599 -0.0556824 13.6417 0.0445459 14.6834 0.0445459C15.004 0.0445459 15.1042 0.154799 15.1042 0.485553C15.1042 1.41768 15.1042 2.35982 15.1042 3.30196C15.1042 3.64274 15.004 3.753 14.6534 3.753C14.0624 3.753 13.4714 3.753 12.8704 3.753C12.2694 3.753 11.8687 4.0637 11.8687 4.75528V6.6095C11.8649 6.66623 11.8649 6.72315 11.8687 6.77989H14.5733C14.994 6.77989 15.1042 6.90016 15.0541 7.32112C14.9339 8.3234 14.8237 9.32569 14.7035 10.328C14.7035 10.6287 14.5633 10.7289 14.2428 10.7289H11.8487V10.9995C11.8487 13.8593 11.8487 16.7159 11.8487 19.569C11.8524 19.6527 11.8351 19.736 11.7984 19.8113C11.7616 19.8866 11.7066 19.9515 11.6383 20Z"
          fill="#1877F2"
        />
      </g>
      <defs>
        <clipPath id="clip0_6714_256058">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  google: (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.9688 11.8708C19.0905 11.2913 19.1299 10.6899 19.1611 10.0964C19.1927 9.49065 19.168 8.88171 19.168 8.26343H10.0176V11.7442H15.3765C15.0672 12.8627 14.2138 14.0806 12.9317 14.8592L15.7062 17.164C17.4226 15.8008 18.516 14.0278 18.9688 11.8708Z"
        fill="#518EF8"
      />
      <path
        d="M4.93681 17.6345C6.16898 18.4778 7.59734 18.99 9.08462 19.1219C11.4849 19.3447 13.6638 18.7369 15.5796 17.2645C15.6227 17.2315 15.665 17.1978 15.7073 17.1641L12.9328 14.8593C12.297 15.246 11.5902 15.5014 10.8541 15.6103C8.34864 15.9839 5.88702 14.6899 4.81481 12.2921C4.77892 12.2128 4.74555 12.1324 4.71398 12.0517L1.91504 14.3257C2.60951 15.6345 3.61677 16.7374 4.93681 17.6345Z"
        fill="#28B446"
      />
      <path
        d="M0.876785 9.07723C0.862431 9.20642 0.847361 9.33524 0.833008 9.46406V10.5384C0.879657 10.8854 0.912311 11.2353 0.976543 11.5791C1.14717 12.5371 1.46402 13.4631 1.91598 14.3249L4.71491 12.0509C4.22299 10.7871 4.20198 9.38854 4.6557 8.11052L1.86108 5.77808C1.33394 6.78928 1.00884 7.89127 0.876785 9.07723Z"
        fill="#FBBB00"
      />
      <path
        d="M6.29298 5.71027C8.02868 4.18593 11.0372 3.67172 13.4644 5.49318L15.607 2.71075C15.1459 2.42727 14.707 2.13015 14.2434 1.87861C13.4077 1.42733 12.502 1.12005 11.5643 0.969671C11.2647 0.920511 10.9611 0.897904 10.6593 0.86202C10.5951 0.854485 10.5312 0.843002 10.467 0.833313H9.46439C9.11667 0.879962 8.76717 0.914769 8.42376 0.976848C6.3425 1.34537 4.59316 2.32321 3.18114 3.88989C2.66305 4.46089 2.21957 5.09537 1.86133 5.7781L4.65596 8.11054C4.98702 7.18344 5.55071 6.35693 6.29298 5.71027Z"
        fill="#F14336"
      />
    </svg>
  ),
  linkedin: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.285 1.39a2.666 2.666 0 0 1 1.807-.357 2.666 2.666 0 0 1 2.193 2.593 2.666 2.666 0 0 1-2.2 2.626 2.666 2.666 0 0 1-3.032-2.146A.86.86 0 0 0 1 3.932v-.586l.042-.16c.03-.12.061-.238.098-.353a2.666 2.666 0 0 1 1.145-1.444zm20.702 12.893a10.873 10.873 0 0 0-.407-2.993 4.213 4.213 0 0 0-2.252-2.786 6.232 6.232 0 0 0-2.846-.533 4.752 4.752 0 0 0-4.232 2.286.472.472 0 0 1-.1.047V8.338H8.784V23h4.559v-7.218c0-.508.033-1.016.1-1.52.2-1.392.906-2.145 2.186-2.292 1.386-.16 2.366.34 2.666 1.853.09.501.136 1.01.14 1.52v7.59h4.552a1.03 1.03 0 0 0 0-.126V22.8c.006-2.85.013-5.694 0-8.517zM5.905 8.664v-.34H1.347V22.986h4.558V8.665z"
        fill="#0A66C2"
      />
    </svg>
  ),
  share: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.996 1.067a6 6 0 0 0-4.22 1.684l-.011.01-1.72 1.71a1 1 0 0 0 1.41 1.418l1.715-1.705a4 4 0 0 1 5.656 5.655l-2.993 2.994a4.001 4.001 0 0 1-6.032-.432 1 1 0 1 0-1.602 1.198 5.999 5.999 0 0 0 9.048.648l3-3 .012-.012a6 6 0 0 0-4.263-10.168zM10.425 8.01a6 6 0 0 0-4.672 1.743l-3 3-.012.012a6 6 0 0 0 8.484 8.484l.012-.012 1.71-1.71a1 1 0 0 0-1.414-1.414l-1.704 1.703a4 4 0 0 1-5.655-5.655l2.993-2.994a4 4 0 0 1 6.032.432 1 1 0 1 0 1.602-1.198 6 6 0 0 0-4.376-2.39z"
        fill="#6B7280"
      />
    </svg>
  ),
  twitter: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none">
      <g id="Icon social/filled/Twitter">
        <rect width="16" height="16" fill="white" />
        <path
          id="Vector"
          d="M15.3333 14.6125L9.72958 7.22938L14.7026 1.3335H12.4385L8.66726 5.82844L5.24791 1.3335H0.666656L6.28368 8.67015L1.29741 14.6457H3.50172L7.346 10.0512L10.8317 14.6125H15.3333ZM4.5906 2.6614L12.6576 13.2846H11.489L3.35566 2.6614H4.5906Z"
          fill="#101419"
        />
      </g>
    </svg>
  ),
  'azure-ad': (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6316_227)">
        <g clipPath="url(#clip1_6316_227)">
          <path
            d="M-0.789308 -2H8.79148V8.29685H-1.50537V-1.28394C-1.50537 -1.47385 -1.42993 -1.65598 -1.29564 -1.79027C-1.16135 -1.92456 -0.97922 -2 -0.789308 -2Z"
            fill="#E05141"
          />
          <path
            d="M20.5032 8.29684L10.2063 8.29684L10.2063 -2L19.7802 -2C19.9701 -2 20.1523 -1.92456 20.2866 -1.79027C20.4208 -1.65598 20.4963 -1.47385 20.4963 -1.28394V8.29684H20.5032Z"
            fill="#67AD5B"
          />
          <path
            d="M-1.50537 9.70488H8.79148V20.0017H-0.789306C-0.883486 20.0017 -0.976741 19.9831 -1.06373 19.9471C-1.15072 19.911 -1.22973 19.8581 -1.29625 19.7914C-1.36276 19.7247 -1.41547 19.6456 -1.45136 19.5585C-1.48724 19.4714 -1.5056 19.3781 -1.50537 19.2839V9.70488Z"
            fill="#4994EB"
          />
          <path
            d="M19.7802 20H10.1994L10.1994 9.70315L20.5032 9.70315V19.2839C20.5032 19.3786 20.4844 19.4722 20.448 19.5596C20.4116 19.6469 20.3582 19.7261 20.291 19.7927C20.2238 19.8593 20.144 19.9119 20.0564 19.9475C19.9687 19.9831 19.8748 20.0009 19.7802 20Z"
            fill="#F4C143"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_6316_227">
          <rect
            width="18.0106"
            height="18"
            fill="white"
            transform="translate(0.494629)"
          />
        </clipPath>
        <clipPath id="clip1_6316_227">
          <rect
            width="22"
            height="22"
            fill="white"
            transform="translate(-1.50537 -2)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  'microsoft': (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6316_227)">
        <g clipPath="url(#clip1_6316_227)">
          <path
            d="M-0.789308 -2H8.79148V8.29685H-1.50537V-1.28394C-1.50537 -1.47385 -1.42993 -1.65598 -1.29564 -1.79027C-1.16135 -1.92456 -0.97922 -2 -0.789308 -2Z"
            fill="#E05141"
          />
          <path
            d="M20.5032 8.29684L10.2063 8.29684L10.2063 -2L19.7802 -2C19.9701 -2 20.1523 -1.92456 20.2866 -1.79027C20.4208 -1.65598 20.4963 -1.47385 20.4963 -1.28394V8.29684H20.5032Z"
            fill="#67AD5B"
          />
          <path
            d="M-1.50537 9.70488H8.79148V20.0017H-0.789306C-0.883486 20.0017 -0.976741 19.9831 -1.06373 19.9471C-1.15072 19.911 -1.22973 19.8581 -1.29625 19.7914C-1.36276 19.7247 -1.41547 19.6456 -1.45136 19.5585C-1.48724 19.4714 -1.5056 19.3781 -1.50537 19.2839V9.70488Z"
            fill="#4994EB"
          />
          <path
            d="M19.7802 20H10.1994L10.1994 9.70315L20.5032 9.70315V19.2839C20.5032 19.3786 20.4844 19.4722 20.448 19.5596C20.4116 19.6469 20.3582 19.7261 20.291 19.7927C20.2238 19.8593 20.144 19.9119 20.0564 19.9475C19.9687 19.9831 19.8748 20.0009 19.7802 20Z"
            fill="#F4C143"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_6316_227">
          <rect
            width="18.0106"
            height="18"
            fill="white"
            transform="translate(0.494629)"
          />
        </clipPath>
        <clipPath id="clip1_6316_227">
          <rect
            width="22"
            height="22"
            fill="white"
            transform="translate(-1.50537 -2)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  sso: (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.7803 0.96967C17.0732 1.26256 17.0732 1.73744 16.7803 2.03033L15.8106 3L17.5303 4.71967C17.8232 5.01256 17.8232 5.48744 17.5303 5.78033L14.9053 8.40533C14.6124 8.69822 14.1375 8.69822 13.8446 8.40533L12.125 6.68566L10.0651 8.74549C10.2957 9.05735 10.4888 9.39636 10.6397 9.7556C10.8888 10.3484 11.0181 10.9845 11.0202 11.6275C11.0224 12.2704 10.8973 12.9074 10.6523 13.5018C10.4072 14.0962 10.047 14.6363 9.5924 15.0909C9.13777 15.5456 8.5977 15.9058 8.00329 16.1508C7.40888 16.3959 6.77186 16.5209 6.12892 16.5188C5.48598 16.5166 4.84982 16.3873 4.25706 16.1383C3.66431 15.8892 3.12666 15.5254 2.67509 15.0678L2.66946 15.0621C1.78144 14.1426 1.2901 12.9112 1.30121 11.633C1.31232 10.3547 1.82502 9.13203 2.72888 8.22817C3.63274 7.3243 4.85546 6.81161 6.13366 6.8005C7.16328 6.79155 8.16255 7.10864 8.99285 7.69645L15.7196 0.96967C16.0125 0.676777 16.4874 0.676777 16.7803 0.96967ZM8.54135 9.26696C8.53437 9.2607 8.52748 9.25428 8.52068 9.24772C7.88415 8.63293 7.03161 8.29275 6.1467 8.30044C5.26178 8.30813 4.41529 8.66308 3.78954 9.28883C3.16379 9.91458 2.80884 10.7611 2.80115 11.646C2.79347 12.5296 3.13262 13.3809 3.74566 14.0171C4.0577 14.3326 4.42891 14.5835 4.83805 14.7554C5.24842 14.9278 5.68884 15.0173 6.13395 15.0188C6.57906 15.0203 7.02007 14.9337 7.43159 14.764C7.8431 14.5944 8.217 14.345 8.53174 14.0303C8.84648 13.7155 9.09586 13.3416 9.26551 12.9301C9.43516 12.5186 9.52173 12.0776 9.52024 11.6325C9.51875 11.1874 9.42922 10.747 9.25682 10.3366C9.0891 9.93735 8.84616 9.57423 8.54135 9.26696ZM13.1856 5.625L14.375 6.81434L15.9393 5.25L14.75 4.06066L13.1856 5.625Z" fill="#6B7280"/>
    </svg>
  )
}

const socialButtonRootVariants = cva(
  'flex items-center justify-center border-[1px] border-solid',
  {
    variants: {
      variant: {
        xsWithNoLabel: 'w-6 h-6',
        smWithNoLabel: 'w-8 h-8',
        mdWithNoLabel: 'w-[38px] h-[38px]',
        lgWithNoLabel: 'w-[42px] h-[42px]',
        xlWithNoLabel: 'w-12 h-12',
        xsWithLabel: 'pl-1.5 pr-2 py-[3px] h-6',
        smWithLabel: 'pl-2.5 pr-3 py-1.5 h-8',
        mdWithLabel: 'pl-4 pr-[18px] py-[9px] h-[38px]',
        lgWithLabel: 'pl-4 pr-[18px] py-[9px] h-[42px]',
        xlWithLabel: 'pr-6 pl-[22px] py-3 h-12'
      },
      disabledLoading: {
        DisabledLoading:
          'pointer-events-none text-gray-400 dark:text-gray-600 border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-900',
        NoDisabledLoading:
          'border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-900 text-gray-600 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800 focus:shadow-sm focus:ring-2 focus:ring-gray-400 dark:focus:ring-gray-600 focus:ring-offset-2 dark:focus:ring-offset-gray-900'
      },
      label: {
        default: 'rounded-full',
        label: 'rounded'
      }
    },
    defaultVariants: {
      variant: 'lgWithNoLabel',
      disabledLoading: 'NoDisabledLoading',
      label: 'default'
    }
  }
)

const socialButtonSpaceIconWithLabelVariants = cva('flex items-center', {
  variants: {
    size: {
      xs: 'ml-1.5',
      sm: 'ml-2',
      md: 'ml-2',
      lg: 'ml-2.5',
      xl: 'ml-2.5'
    }
  },
  defaultVariants: {
    size: 'lg'
  }
})

const socialButtonWithLabelVariants = cva('', {
  variants: {
    size: {
      xs: 'text-xs',
      sm: 'text-sm',
      md: 'text-sm',
      lg: 'text-base',
      xl: 'text-base'
    }
  },
  defaultVariants: {
    size: 'lg'
  }
})

const socialButtonLoadingVariants = cva(
  'animate-spin fill-gray-400 text-transparent dark:fill-gray-600',
  {
    variants: {
      size: {
        xl: 'w-[22px] h-[22px]',
        lg: 'w-5 h-5',
        md: 'w-[18px] h-[18px]',
        sm: 'w-4 h-4',
        xs: 'w-3.5 h-3.5'
      }
    },
    defaultVariants: {
      size: 'lg'
    }
  }
)

const sizeSocialButtonWithLabel = {
  xs: 12,
  sm: 14,
  md: 16,
  lg: 18,
  xl: 20
}

type ISocialProps = 'facebook' | 'google' | 'linkedin' | 'share' | 'twitter' | 'azure-ad' | 'microsoft' | 'sso'

interface SocialButtonProps {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  label?: string
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  isLoading?: boolean
  isDisabled?: boolean
  type: ISocialProps
  fontWeight?: string
  asChild?: boolean
  className?: string
}

const SocialButton = forwardRef<HTMLButtonElement, SocialButtonProps>(
  (props, ref) => {
    const {
      label,
      size = 'lg',
      isDisabled,
      isLoading,
      onClick,
      type,
      fontWeight = 'font-medium',
      asChild = false,
      className = ''
    } = props

    const renderTitleButton = () => {
      if (label) {
        return (
          <>
            <div
              className={cn(
                (isDisabled || isLoading) === true ? 'opacity-50' : ''
              )}>
              <div
                className="flex items-center justify-center"
                style={{
                  width: sizeSocialButtonWithLabel[size],
                  height: sizeSocialButtonWithLabel[size]
                }}>
                {ICONS_SOCIAL[type]}
              </div>
            </div>
            <div
              className={cn(socialButtonSpaceIconWithLabelVariants({ size }))}>
              <span
                className={cn(
                  socialButtonWithLabelVariants({ size, className: fontWeight })
                )}>
                {label}
              </span>
            </div>
          </>
        )
      }

      return (
        <div
          className={cn(
            'flex items-center justify-center',
            (isDisabled || isLoading) === true ? 'opacity-50' : ''
          )}>
          <div
            className="flex items-center justify-center"
            style={{
              width: sizeSocialButtonWithLabel[size],
              height: sizeSocialButtonWithLabel[size]
            }}>
            {ICONS_SOCIAL[type]}
          </div>
        </div>
      )
    }

    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        ref={ref}
        type="button"
        onClick={(event) => {
          if (onClick) {
            onClick(event)
          }
        }}
        className={cn(
          socialButtonRootVariants({
            variant: `${size}${!label ? 'WithNoLabel' : 'WithLabel'}`,
            disabledLoading:
              (isDisabled || isLoading) === true
                ? 'DisabledLoading'
                : 'NoDisabledLoading',
            label: label ? 'label' : 'default'
          }),
          className
        )}>
        {isLoading ? (
          <div className="flex items-center justify-center">
            <svg
              aria-hidden="true"
              className={cn(socialButtonLoadingVariants({ size }))}
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>

            {label ? (
              <div
                className={cn(
                  socialButtonSpaceIconWithLabelVariants({ size })
                )}>
                <span
                  className={cn(
                    socialButtonWithLabelVariants({
                      size,
                      className: fontWeight
                    })
                  )}>
                  {label}
                </span>
              </div>
            ) : null}
          </div>
        ) : (
          renderTitleButton()
        )}
      </Comp>
    )
  }
)

SocialButton.displayName = 'SocialButton'

export { SocialButton }
export type { SocialButtonProps, ISocialProps }
