import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { DropResult } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import CreateCandidateModal from '~/components/Candidates/CreateCandidateModal'
import { ChangeJobStageWithModalActionProps } from '~/components/ChangeJobStageWithModal/ChangeJobStageWithModalView'
import { ISwitchLayoutView } from '~/components/SwitchLayout/SwitchLayoutView'
import configuration from '~/configuration'
import { IRouterWithID } from '~/core/@types/global'
import { openAlert } from '~/core/ui/AlertDialog'
import { Badge } from '~/core/ui/Badge'
import { DebouncedInput } from '~/core/ui/DebouncedInput'
import If from '~/core/ui/If'
import { KanbanContext } from '~/core/ui/Kanban'
import { SplitButton } from '~/core/ui/SplitButton'
import { cn } from '~/core/ui/utils'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import { reorder } from '~/core/utilities/common'
import { useCreatePlacementModel } from '~/features/placements/components/CreatePlacementDialog'
import { useOpenPlacementDetailDialog } from '~/features/placements/components/PlacementDetailDialog'
import useDeletePlacement from '~/features/placements/components/use-delete-placement'
import useCandidateStore from '~/lib/features/candidates/store'
import QueryChangeStageJobMutation from '~/lib/features/jobs/graphql/submit-change-stage-job-mutation'
import {
  IJobApplicants,
  IJobStages,
  IStageTypes
} from '~/lib/features/jobs/types'
import {
  JOB_APPLICANT_STATUS,
  JOB_STAGE_GROUP,
  JOB_STATUS_ENUM,
  PLACE_VIEW_PIPELINE_ENUM
} from '~/lib/features/jobs/utilities/enum'
import usePermissionJob from '~/lib/features/permissions/hooks/use-permission-job'
import { IPlaceViewPipeline } from '~/lib/features/settings/hiring-pipelines/types'
import useDetectCompanyWithKind from '~/lib/hooks/use-detect-company-with-kind'
import { useClient } from '~/lib/hooks/use-is-client'
import { useSubmitCommon } from '~/lib/hooks/use-submit-graphql-common'
import useBoundStore from '~/lib/store'
import JobStageKanban from './components/JobStageKanban'

export type IRefetchActions = Array<{
  label: string
  action: 'added' | 'deleted' | 'refetch'
  extraData?: {
    id: number | string
    item?: object
  }
}>

export interface IStageKanban {
  id: number
  label: string
  jobStageId?: number | string
  stageTypeId?: number | string
  stageGroup?: string
  data: Array<IJobApplicants>
  totalCount?: number
}

type IStagesKanban = Array<IStageKanban>

interface CandidatesTabContentProps extends ChangeJobStageWithModalActionProps {
  getUUidV4?: string
  jobStatus: string
  jobStages?: IJobStages
  stageTypes: IStageTypes
  jobId?: IRouterWithID
  isDragDisabled?: boolean
  actions?: {
    configSwitchLayout: {
      path: Array<string>
      redirectUrls: Array<string>
    }
    setConfigSwitchLayout: (param: {
      path: Array<string>
      redirectUrls: Array<string>
    }) => void
    switchView: ISwitchLayoutView
    setSwitchView: (param: ISwitchLayoutView) => void
  }
  jobTitle?: string
  companyName?: string
  placeViewPipeline?: IPlaceViewPipeline
  callback?: () => void
  openHiringSuccessModel: ChangeJobStageWithModalActionProps['openHiringSuccessModel']
}

const CandidatesTabContent: FC<CandidatesTabContentProps> = ({
  getUUidV4,
  jobStatus,
  jobStages = [],
  stageTypes = [],
  jobId,
  actions,
  isDragDisabled = false,
  jobTitle,
  companyName,
  placeViewPipeline = PLACE_VIEW_PIPELINE_ENUM.jobDetail,
  callback,
  setOpenMarkAsHired,
  setApplicantCurrent,
  openHiringSuccessModel
}) => {
  const { t } = useTranslation()
  const { isClient } = useClient()
  const { setToast, setRefetchMyList, setShowLockApp, setCloseLockApp } =
    useBoundStore()
  const { setOpenUploadDrawer, setUploadDrawerJobId } = useCandidateStore()
  const { actionJob } = usePermissionJob()

  const { trigger: triggerChangeStages, isLoading } = useSubmitCommon(
    QueryChangeStageJobMutation
  )

  const [isFirstLoading, setFirstLoading] = useState(true)
  const [searchState, setSearch] = useState('')
  const [statusState, setStatusState] = useState(
    JOB_APPLICANT_STATUS.inprocessing
  )
  const [applicants, setApplicants] = useState<{
    collection: Array<IJobApplicants>
    metadata?: {
      totalCount?: number
    }
  }>({
    collection: [],
    metadata: {
      totalCount: undefined
    }
  })
  const [openCreateCandidate, setOpenCreateCandidate] = useState<boolean>(false)
  const [stages, setStages] = useState<IStagesKanban>(
    jobStages
      .sort((a, b) => (a.index || 0) - (b.index || 0))
      .map((item, index) => {
        return {
          id: index + 1,
          jobStageId: item.id,
          label: item.stageLabel,
          stageTypeId: item.stageTypeId,
          stageGroup: item.stageGroup,
          data: [],
          totalCount: undefined
        }
      })
  )
  const [refetchActions, setRefetchActions] = useState<IRefetchActions>([])

  const applicantsRef = useRef<any[]>([])

  const isDragDisabledKanban =
    !actionJob.update ||
    statusState === JOB_APPLICANT_STATUS.rejected ||
    isDragDisabled

  useEffect(() => {
    const cloneStages = JSON.parse(JSON.stringify(jobStages))
    reloadKanbanAfterEditPipeline(stages, cloneStages)
  }, [jobStages])

  useEffect(() => {
    if (applicants.collection) {
      prepareDataForSwitchLayout()
    }
  }, [applicants])

  useEffect(() => {
    return () => {
      setFirstLoading(false)
      applicantsRef.current = []
    }
  }, [])

  // --------------- FUNCTION HANDLER ---------------

  const reloadKanbanAfterEditPipeline = (
    currentStages: IStagesKanban,
    newStage: IJobStages
  ) => {
    if (isFirstLoading === false) {
      applicantsRef.current = []
    }
    let sortedStages = newStage
      .sort((a, b) => (a.index || 0) - (b.index || 0))
      .map((item, index) => {
        return {
          id: index + 1,
          jobStageId: item.id,
          label: item.stageLabel,
          stageTypeId: item.stageTypeId,
          stageGroup: item.stageGroup,
          totalCount: 0,
          data: [] as Array<IJobApplicants>
        }
      })

    currentStages.forEach((s) => {
      let findIndexStage = sortedStages.findIndex(
        (t) => t.jobStageId === s.jobStageId
      )
      if (findIndexStage !== -1) {
        sortedStages[findIndexStage].data = s.data
        sortedStages[findIndexStage].totalCount = Number(s.totalCount || 0)
      }
    })

    setStages(sortedStages)
  }

  const prepareDataForSwitchLayout = () => {
    const mappingStageIds = stageTypes.map((item) => item.id)
    const mappingRedirectUrls = [] as Array<string>
    const applicantCollections = applicants.collection || []

    mappingStageIds.forEach((s: string) => {
      const filter = applicantCollections.filter(
        (item: { jobStage?: { stageTypeId?: number } }) =>
          String(item.jobStage?.stageTypeId) === String(s)
      )
      if (filter.length) {
        filter.forEach((item: IJobApplicants) => {
          if (item.id) {
            mappingRedirectUrls.push(
              configuration.path.candidates.detail(item?.profile?.id, item.id)
            )
          }
        })
      }
    })

    if (actions?.setConfigSwitchLayout) {
      actions?.setConfigSwitchLayout({
        path: actions?.configSwitchLayout.path,
        redirectUrls: mappingRedirectUrls
      })
    }
  }

  const { deletePlacement } = useDeletePlacement()

  const move = (
    source = [],
    destination = [],
    droppableSource = { index: 0, droppableId: '0' },
    droppableDestination = { index: 0, droppableId: '0' }
  ) => {
    const sourceClone = Array.from(source)
    const destClone = Array.from(destination)
    const [removed] = sourceClone.splice(droppableSource.index, 1)

    destClone.splice(droppableDestination.index, 0, removed)

    const result = {} as {
      [key: string]: object
    }
    result[droppableSource.droppableId] = sourceClone
    result[droppableDestination.droppableId] = destClone

    return result
  }
  const isAgency = useDetectCompanyWithKind({ kind: 'agency' }).isCompanyKind
  const { PlacementModalComponent, openPlacementModel } =
    useCreatePlacementModel()

  const onDragEnd = useCallback(
    async (result: DropResult) => {
      const { source, destination } = result
      if (!destination) {
        return
      }
      const draggableId = result.draggableId
      const cloneData = JSON.parse(JSON.stringify(stages))
      const applicantList = stages
        .map((item) => item.data)
        .flat()
        .find((i) => Number(i.id) === Number(draggableId))

      const sInd = +source.droppableId
      const dInd = +destination.droppableId
      const sItem = jobStages.find((_, index) => String(index) === String(sInd))
      const dItem = jobStages.find((_, index) => String(index) === String(dInd))

      const moveStage = (
        isCallApi = true,
        desApplicantDataMerge?: IJobApplicants
      ) => {
        const resultMove = move(
          cloneData[sInd].data,
          cloneData[dInd].data,
          source,
          destination
        )

        const applicantId = Number(cloneData[sInd].data[result.source.index].id)
        const jobStageId = Number(cloneData[dInd].jobStageId)
        cloneData[sInd].data = resultMove[sInd]
        cloneData[sInd].totalCount = cloneData[sInd].totalCount - 1
        cloneData[dInd].data = desApplicantDataMerge
          ? (resultMove[dInd] as any).map((item: any) =>
              item.id === desApplicantDataMerge?.id
                ? { ...item, ...desApplicantDataMerge }
                : item
            )
          : resultMove[dInd]
        cloneData[dInd].totalCount = cloneData[dInd].totalCount + 1

        if (isCallApi) {
          if (isLoading) {
            return
          }

          triggerChangeStages({
            id: applicantId,
            jobStageId
          }).then((result) => {
            if (result.error) {
              setTimeout(() => {
                const resultMove = move(
                  cloneData[dInd].data,
                  cloneData[sInd].data,
                  destination,
                  source
                )

                cloneData[sInd].data = resultMove[sInd]
                cloneData[dInd].data = resultMove[dInd]

                setStages(cloneData)
                applicantsRef.current = cloneData
              }, 1500)

              return catchErrorFromGraphQL({
                error: result.error,
                page: configuration.path.jobs.list,
                setToast
              })
            }

            const { applicantsChangeStage } = result.data
            if (applicantsChangeStage.applicant.id) {
              if (cloneData[dInd]?.data?.length === 1) {
                setRefetchActions([
                  { label: cloneData[dInd]?.label, action: 'refetch' }
                ])
              }

              setToast({
                open: true,
                type: 'success',
                title:
                  sItem?.stageGroup === JOB_STAGE_GROUP.hires &&
                  dItem?.stageGroup !== JOB_STAGE_GROUP.hires
                    ? isAgency
                      ? `${applicantList?.profile?.fullName} moved to the ${dItem?.stageLabel} stage; placement deleted.`
                      : `${t('notification:mark_as_hired:remove', {
                          name: applicantList?.profile?.fullName,
                          hired: '"hired"'
                        })}`
                    : sItem?.stageGroup !== JOB_STAGE_GROUP.hires &&
                      dItem?.stageGroup === JOB_STAGE_GROUP.hires
                    ? `${t('placements:placementCreated')}`
                    : t('notification:jobs:detail:changesSaved')
              })
              callback && callback()
              setApplicantCurrent && setApplicantCurrent({})
            }

            return
          })
        }

        setStages(cloneData)
        applicantsRef.current = cloneData
      }
      if (dItem?.id === sItem?.id) {
        return
      }
      if (dItem?.stageGroup === JOB_STAGE_GROUP.hires) {
        if (isAgency) {
          if (!applicantList?.placement) {
            openPlacementModel({
              header: {
                candidateName: applicantList?.profile?.fullName,
                jobTitle: jobTitle,
                companyName: companyName
              },
              applicant: {
                hiredDate: new Date(),
                createdAt: applicantList?.createdAt
              },
              defaultValues: {
                applicationId: applicantList?.id,
                jobStageId: jobStages.find(
                  (_, index) => String(index) === String(dInd)
                )?.id
              },
              onPlacementCreated: (placement) => {
                const applicant = {
                  item: {
                    ...applicantList,
                    jobStageId: Number(
                      jobStages.find(
                        (_, index) => String(index) === String(dInd)
                      )?.id
                    )
                  } as any,
                  callback: () => {
                    moveStage(false)
                    // callback && callback()
                    setApplicantCurrent && setApplicantCurrent({})
                  }
                }
                setApplicantCurrent &&
                  setApplicantCurrent({
                    item: {
                      ...applicantList,
                      jobStageId: Number(
                        jobStages.find(
                          (_, index) => String(index) === String(dInd)
                        )?.id
                      )
                    } as any,
                    callback: () => {
                      moveStage(false)
                      callback && callback()
                      setApplicantCurrent && setApplicantCurrent({})
                    }
                  })
                openHiringSuccessModel &&
                  openHiringSuccessModel({
                    applicant: { ...applicant.item, job: { title: jobTitle } },
                    placement: placement
                  })
                moveStage(false, { ...applicantList, placement } as any)
                // setTimeout(() => {
                //   setRefetchMyList(true)
                // }, 100)
              }
            })
          } else {
            moveStage(true)
          }
        } else {
          // handle for case: move candidate in hires group
          if (sItem?.stageGroup === JOB_STAGE_GROUP.hires) {
            if (dItem?.id !== sItem?.id) {
              moveStage(true)
            }

            return
          } else {
            setApplicantCurrent &&
              setApplicantCurrent({
                item: {
                  ...applicantList,
                  jobStageId: Number(dItem?.id)
                } as any,
                callback: () => {
                  moveStage(false)
                  callback && callback()
                  setApplicantCurrent && setApplicantCurrent({})
                }
              })
            setOpenMarkAsHired && setOpenMarkAsHired(true)
            return
          }
        }

        return
      }
      if (sItem?.stageGroup === JOB_STAGE_GROUP.hires) {
        if (isAgency) {
          const destStage = jobStages.find(
            (_, index) => String(index) === String(dInd)
          )
          openAlert({
            isPreventAutoFocusDialog: false,
            className: 'w-[480px]',
            title: `${t('common:modal:move_back_to_stage', {
              stageLabel: destStage?.stageLabel
            })}`,
            description: (
              <div
                dangerouslySetInnerHTML={{
                  __html: t('common:modal:move_back_to_stage_description', {
                    fullName: applicantList?.profile?.fullName,
                    stageLabel: destStage?.stageLabel
                  })
                }}
              />
            ),
            actions: [
              {
                label: `${t('button:cancel')}`,
                type: 'secondary',
                size: 'sm'
              },
              {
                label: `${t('button:move')}`,
                type: 'destructive',
                size: 'sm',
                onClick: async () => {
                  setShowLockApp('')
                  deletePlacement({ id: +applicantList?.placement?.id }).then(
                    (res) => {
                      if (!!res.data?.placementsDelete?.success) {
                        setCloseLockApp()
                      }
                    }
                  )
                  moveStage(true, { ...applicantList, placement: null } as any)
                  // setTimeout(() => {
                  //   setRefetchMyList(true)
                  // }, 100)
                }
              }
            ]
          })
        } else {
          openAlert({
            isPreventAutoFocusDialog: false,
            className: 'w-[480px]',
            title: `${t('common:modal:unmark_as_hired_title')}`,
            description: t('common:modal:unmark_as_hired_description', {
              title: applicantList?.profile?.fullName
            }),
            actions: [
              {
                label: `${t('button:cancel')}`,
                type: 'secondary',
                size: 'sm'
              },
              {
                label: `${t('button:unmarkAsHired')}`,
                type: 'destructive',
                size: 'sm',
                onClick: async () => {
                  moveStage(true)
                }
              }
            ]
          })
        }

        return
      }

      if (sInd === dInd) {
        const items = reorder(
          cloneData[sInd].data,
          source.index,
          destination.index
        )
        cloneData[sInd].data = items

        setStages(cloneData)
        applicantsRef.current = cloneData
      } else {
        moveStage(true)
      }
    },
    [callback, isLoading, jobStages, stages, triggerChangeStages]
  )
  const { PlacementDetailModalComponent, openPlacementDetailModel } =
    useOpenPlacementDetailDialog()

  const handleCallbackLoaded = (
    newValue: IStageKanban & {
      totalCount: number
    }
  ) => {
    if (applicantsRef?.current) {
      if (applicantsRef.current.length === stages.length) {
        let applicantCollections = JSON.parse(JSON.stringify(stages))
        const findIndexStage = applicantCollections.findIndex(
          (item: IStageKanban) => item.id === newValue.id
        )

        if (findIndexStage !== -1) {
          applicantCollections[findIndexStage].data = newValue.data
          applicantCollections[findIndexStage].totalCount = newValue.totalCount

          const flatCollection = applicantCollections
            ?.map((item: IStageKanban) => item.data)
            .flat()
          const flatTotalCount = applicantCollections
            ?.map((item: { totalCount: number }) => item.totalCount)
            .flat()

          setApplicants({
            collection: flatCollection,
            metadata: {
              totalCount: flatTotalCount.reduce(
                (total: number, x: number) => total + x,
                0
              )
            }
          })
          setStages(applicantCollections)
        }
      } else {
        if (applicantsRef.current.length === stages.length - 1) {
          const applicantCollections = [
            ...applicantsRef.current,
            newValue
          ].sort((a, b) => a.id - b.id)

          const flatCollection = applicantCollections
            ?.map((item) => item.data)
            .flat()
          const flatTotalCount = applicantCollections
            ?.map((item) => item.totalCount)
            .flat()

          applicantsRef.current.push(newValue)
          setApplicants({
            collection: flatCollection,
            metadata: {
              totalCount: flatTotalCount.reduce((total, x) => total + x, 0)
            }
          })
          setStages(applicantCollections)
          setFirstLoading(false)
        } else {
          applicantsRef.current.push(newValue)
        }
      }
    }
  }

  return (
    <>
      <PlacementModalComponent />
      <PlacementDetailModalComponent />
      <If condition={placeViewPipeline === PLACE_VIEW_PIPELINE_ENUM.jobDetail}>
        <div className="mb-3 flex justify-between px-6">
          <div className="flex items-center justify-between rounded bg-gray-100 p-px dark:bg-gray-800">
            {[
              {
                value: JOB_APPLICANT_STATUS.inprocessing,
                label: `${t('button:qualified')}`
              },
              {
                value: JOB_APPLICANT_STATUS.rejected,
                label: `${t('button:disqualified')}`
              }
            ].map((item) => (
              <button
                type="button"
                key={item.value}
                className={cn(
                  `group rounded px-2 py-[2px] text-gray-900 hover:text-gray-600 dark:bg-gray-700 dark:text-gray-300 dark:hover:text-gray-300`,
                  item.value === statusState
                    ? 'bg-white shadow hover:bg-white hover:shadow'
                    : ''
                )}>
                <div
                  className="flex items-center space-x-2"
                  onClick={() => {
                    setFirstLoading(true)
                    applicantsRef.current = []
                    setApplicants({
                      collection: [],
                      metadata: {
                        totalCount: 0
                      }
                    })

                    setStatusState(item.value)
                    setTimeout(() => {
                      setRefetchMyList(true)
                    }, 100)
                  }}>
                  <span className="text-xs font-medium">{item.label}</span>
                  {item.value === statusState &&
                  applicants?.metadata?.totalCount ? (
                    <Badge radius="circular" size="sm" color="gray">
                      {applicants?.metadata?.totalCount}
                    </Badge>
                  ) : null}
                </div>
              </button>
            ))}
          </div>

          <div className="flex items-center space-x-2">
            <DebouncedInput
              value={searchState}
              onChange={(value) => {
                setSearch(String(value))
                setTimeout(() => {
                  setRefetchMyList(true)
                }, 100)
              }}
              onClear={() => {
                setSearch('')
                setTimeout(() => {
                  setRefetchMyList(true)
                }, 100)
              }}
              placeholder={`${t('label:placeholder:search')}`}
              size="xs"
            />
            <If
              condition={
                actionJob.create &&
                jobStatus !== JOB_STATUS_ENUM.archived &&
                jobStatus !== JOB_STATUS_ENUM.draft
              }>
              <SplitButton
                label={`${t('button:new')}`}
                size="xs"
                menu={[
                  {
                    label: `${t('button:uploadCvsResume')}`,
                    icon: 'Upload',
                    onClick: () => {
                      setUploadDrawerJobId(Number(jobId))
                      setOpenUploadDrawer(true)
                    }
                  }
                ]}
                onClick={() => setOpenCreateCandidate(true)}
                htmlType="button"
                iconMenus="Plus"
              />
            </If>
          </div>
        </div>
      </If>

      <div
        className={
          placeViewPipeline === PLACE_VIEW_PIPELINE_ENUM.jobList
            ? 'mt-1 max-w-[calc(100vw_-_108px)] pt-2'
            : 'bg-gray-50 pl-6'
        }>
        {isClient ? (
          <KanbanContext onDragEnd={onDragEnd}>
            {stages.map((el, ind) => (
              <div className="relative flex max-w-[280px] flex-col" key={ind}>
                <JobStageKanban
                  getUUidV4={getUUidV4}
                  actions={actions}
                  placeViewPipeline={placeViewPipeline as IPlaceViewPipeline}
                  isDragDisabledKanban={isDragDisabledKanban}
                  el={el}
                  ind={ind}
                  callback={callback}
                  searchState={searchState}
                  statusState={statusState}
                  jobId={jobId}
                  callbackLoaded={handleCallbackLoaded}
                  refetchActions={refetchActions}
                  isFirstLoading={isFirstLoading}
                  openPlacementDetailModel={openPlacementDetailModel}
                  jobTitle={jobTitle}
                  companyName={companyName}
                />
              </div>
            ))}
          </KanbanContext>
        ) : null}
      </div>

      <CreateCandidateModal
        open={openCreateCandidate}
        setOpen={setOpenCreateCandidate}
        defaultValue={{
          jobId: [
            {
              supportingObj: {
                name: jobTitle || ''
              },
              value: jobId as string
            }
          ]
        }}
        reload={() => {
          if (stages?.[0]?.label) {
            setRefetchActions([
              { label: stages?.[0]?.label, action: 'refetch' }
            ])
          }
        }}
      />
    </>
  )
}

export default CandidatesTabContent
