import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import RejectApplicantView from '~/components/Candidates/Profile/components/Reject/RejectApplicantView'
import { useRequestState } from '~/core/hooks/use-request-state'
import { IconButton } from '~/core/ui/IconButton'
import { Tooltip } from '~/core/ui/Tooltip'
import { IApplicableJobs } from '~/lib/features/calendar/types'
import { ICandidateApplicant } from '~/lib/features/candidates/types'

const DisqualifyCandidateAction: FC<{
  applicantId: number
  email: string[]
  id: string
  fullName: string
  callback: () => void
  defaultValue: IApplicableJobs[]
}> = ({ applicantId, email, id, fullName, callback, defaultValue }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [applicant, setApplicant] = useState<ICandidateApplicant>()
  const {
    setRefetch,
    state: { refetch }
  } = useRequestState()
  return (
    <div>
      <Tooltip content={`${t('tooltip:disqualify')}`}>
        <IconButton
          onClick={() => {
            setOpen(true)
            setApplicant(defaultValue[0] as any)
          }}
          type="secondary-destructive"
          size="xs"
          iconMenus="Slash"
        />
      </Tooltip>
      {open && applicantId ? (
        <RejectApplicantView
          onEmailUpdateProfile={callback}
          email={email}
          id={id}
          fullName={fullName}
          setApplicant={setApplicant}
          applicant={applicant}
          setOpen={setOpen}
          open={open}
          setRefetch={setRefetch}
        />
      ) : null}
    </div>
  )
}

export default DisqualifyCandidateAction
