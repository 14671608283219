'use client'

import { cva } from 'class-variance-authority'
import { forwardRef } from 'react'
import IconWrapper, { LucideIconName } from '~/core/ui/IconWrapper'
import { cn } from '~/core/ui/utils'
import { DropdownMenu } from '~/core/ui/DropdownMenu'

export const splitButtonVariants = cva(
  'border-r border-solid border-primary-600 flex items-center space-x-1',
  {
    variants: {
      size: {
        xs: 'py-[3px] px-2 h-6',
        s: 'py-1.5 px-3 h-8',
        sm: 'py-[9px] px-[18px] h-[38px]',
        md: 'py-[9px] px-[18px] h-[42px]'
      }
    },
    defaultVariants: {
      size: 'sm'
    }
  }
)

export const splitIconVariants = cva(
  'flex cursor-pointer items-center justify-center hover:bg-primary-600 rounded-r',
  {
    variants: {
      size: {
        xs: 'p-[5px]',
        s: 'p-[7px]',
        sm: 'p-[9px]',
        md: 'p-[10px]'
      },
      selected: {
        default: '',
        selected: 'bg-primary-600'
      }
    },
    defaultVariants: {
      size: 'sm',
      selected: 'default'
    }
  }
)

const getSplitButtonIcon = {
  md: 22,
  sm: 20,
  s: 18,
  xs: 14
}

const getSplitButtonLabel = {
  md: 'text-base',
  sm: 'text-sm',
  s: 'text-sm',
  xs: 'text-xs'
}

type SplitButtonSizeProps = 'xs' | 's' | 'sm' | 'md'
type SplitButtonMenuItemOption = {
  label?: string
  icon?: LucideIconName
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
}
type SplitButtonMenuItemString = 'separate-line'
type SplitButtonMenuItem = SplitButtonMenuItemOption | SplitButtonMenuItemString

interface SplitButtonProps {
  size?: SplitButtonSizeProps
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  label: string
  menu?: Array<SplitButtonMenuItem | undefined>
  htmlType?: 'button' | 'submit' | 'reset'
  iconMenus?: LucideIconName
  hideIcon?: boolean
}

const SplitButton = forwardRef<HTMLButtonElement, SplitButtonProps>(
  (props, ref) => {
    const {
      size = 'sm',
      onClick,
      label = '',
      menu = [],
      htmlType = 'button',
      iconMenus,
      hideIcon = false
    } = props
    return (
      <div className="relative flex items-center rounded bg-primary-400">
        <button
          ref={ref}
          type={htmlType}
          onClick={(event) => {
            if (onClick) {
              onClick(event)
            }
          }}
          className={splitButtonVariants({
            size,
            className: hideIcon ? 'border-none' : ''
          })}>
          {iconMenus ? (
            <IconWrapper
              size={getSplitButtonIcon[size]}
              name={iconMenus}
              className="-ml-0.5 text-white"
            />
          ) : null}
          <span
            className={cn(
              'block font-medium text-white',
              getSplitButtonLabel[size],
              'leading-none'
            )}>
            {label}
          </span>
        </button>

        {hideIcon === false ? (
          <DropdownMenu
            side="bottom"
            align="end"
            menuClassName="w-auto"
            trigger={(openDropdown) => (
              <div
                className={splitIconVariants({
                  size,
                  selected: openDropdown ? 'selected' : 'default'
                })}>
                <IconWrapper
                  size={getSplitButtonIcon[size]}
                  name="ChevronDown"
                  className="text-white"
                />
              </div>
            )}
            menu={menu}
          />
        ) : null}
      </div>
    )
  }
)

SplitButton.displayName = 'SplitButton'

export { SplitButton }
export type { SplitButtonMenuItem, SplitButtonProps, SplitButtonSizeProps }
