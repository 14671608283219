import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ScheduleInterviewModal from '~/components/Calendar/ScheduleInterview/ScheduleInterviewModal'
import { IconButton } from '~/core/ui/IconButton'
import { Tooltip } from '~/core/ui/Tooltip'
import QueryCreateInterviewMutation from '~/lib/features/calendar/graphql/mutation-create-interview'
import {
  IApplicableJobs,
  InterviewParamsType
} from '~/lib/features/calendar/types'
import { ICandidateProfile } from '~/lib/features/candidates/types'
import { useSubmitCommon } from '~/lib/hooks/use-submit-graphql-common'
import useBoundStore from '~/lib/store'

const ScheduleInterviewAction: FC<{
  reload: () => void
  applicantId?: number
  candidateProfile?: ICandidateProfile
}> = ({ applicantId, candidateProfile, reload }) => {
  const { t } = useTranslation()
  const user = useBoundStore((state) => state.user)
  const [openScheduleInterview, setOpenScheduleInterview] =
    useState<boolean>(false)
  const { trigger: triggerCreateInterview } = useSubmitCommon(
    QueryCreateInterviewMutation
  )
  const onCloseScheduleInterview = useCallback(() => {
    setOpenScheduleInterview(false)
  }, [setOpenScheduleInterview])

  const onReopenScheduleInterview = useCallback(() => {
    setOpenScheduleInterview(true)
  }, [setOpenScheduleInterview])

  const onCreateInterview = useCallback(
    (data: InterviewParamsType) => {
      return triggerCreateInterview({
        ...data,
        applicantId: Number(applicantId)
      }).then((result) => {
        if (result.error) {
          return true
        }

        reload && reload()

        return false
      })
    },
    [applicantId, triggerCreateInterview]
  )
  return (
    <div>
      <Tooltip content={`${t('tooltip:interview')}`}>
        <IconButton
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            setOpenScheduleInterview(true)
          }}
          type="secondary"
          size="xs"
          iconMenus="CalendarPlus"
        />
      </Tooltip>
      <ScheduleInterviewModal
        callbackSubmitAddEmail={() => reload && reload()}
        reload={() => reload && Promise.resolve(reload())}
        open={openScheduleInterview}
        onClose={onCloseScheduleInterview}
        reopenInterviewModal={onReopenScheduleInterview}
        interviewInfo={{
          attendees: [],
          ...(applicantId
            ? {
                applicant: {
                  ...(candidateProfile?.applicants || []).filter(
                    (item: IApplicableJobs) =>
                      String(item.id) === String(applicantId)
                  )?.[0],
                  id: applicantId?.toString()
                }
              }
            : {}),
          ...(user?.id
            ? {
                organizer: {
                  id: Number(user?.id),
                  avatarVariants: user?.avatarVariants,
                  fullName: user?.fullName || '',
                  email: user?.email || ''
                }
              }
            : {}),
          profile:
            !!candidateProfile?.id ||
            !!candidateProfile?.fullName ||
            !!candidateProfile?.email ||
            !!candidateProfile?.applicants
              ? {
                  ...(candidateProfile?.id ? { id: candidateProfile?.id } : {}),
                  ...(candidateProfile?.fullName
                    ? { fullName: candidateProfile?.fullName }
                    : {}),
                  ...(candidateProfile?.email
                    ? {
                        email: Array.isArray(candidateProfile?.email)
                          ? candidateProfile?.email?.[0]
                          : candidateProfile?.email
                      }
                    : {}),
                  ...(candidateProfile?.applicants
                    ? { applicants: candidateProfile?.applicants }
                    : {}),
                  ...(candidateProfile?.avatarVariants
                    ? { avatarVariants: candidateProfile?.avatarVariants }
                    : {}),
                  applicableJobs: candidateProfile?.applicableJobs
                }
              : {}
        }}
        onFinish={onCreateInterview}
        candidateProfile={{
          id: Number(candidateProfile?.id),
          email: candidateProfile?.email
        }}
      />
    </div>
  )
}

export default ScheduleInterviewAction
