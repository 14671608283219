import { gql } from 'urql'
import { ILogoAndAvatarVariants } from '~/core/@types/global'

const QueryHiringMembers = gql<
  {
    membersList: {
      collection: {
        id: number
        email: string
        fullName: string
        avatarVariants?: ILogoAndAvatarVariants
        defaultColour: string
        roles: {
          id: number
          name: string
        }
      }[]
      metadata: {
        totalCount: number
      }
    }
  },
  { limit?: number; page?: number; search?: string }
>`
  query ($limit: Int!, $page: Int!, $search: String) {
    membersList(limit: $limit, page: $page, search: $search) {
      collection {
        id
        email
        fullName
        avatarVariants
        defaultColour
        roles {
          id
          name
        }
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QueryHiringMembers
